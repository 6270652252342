<template>
  <section>
    <div class="home-sub-title text-center">
      <p>5501 Adams ST Unit C Matteson, IL 60443</p>
    </div>
    <div class="home-welcome text-center">
      <h2>Contact E & A Truck Sales</h2>
    </div>
  </section>
  <section class="my-5">
    <div class="container">
      <div class="row">
        <div class="col-8 mx-auto">
          <div class="link-title mb-5">
            <div>
              <p>Anton Churkin – President</p>
              <p class="fw-bold">eandatrucksales@gmail.com</p>
            </div>
            <div>
              <p class="fw-bold">E & A Truck Sales</p>
              <p>5501 Adams ST Unit C Matteson, IL 60443</p>
              <p class="text-decoration-underline mb-2">
                <a
                  href="https://www.google.com/maps/place/Adams+St,+Matteson,+IL+60443,+%D0%A1%D0%A8%D0%90/@41.4975057,-87.7503792,17z/data=!3m1!4b1!4m5!3m4!1s0x880e112d1b18a8c3:0xeed461681889d531!8m2!3d41.4975057!4d-87.7481905"
                  target="_blank"
                  >Get Direction</a
                >
              </p>
              <p><span class="fw-bold">Phone:</span> (312) 678-0974</p>
            </div>
          </div>
          <div class="mb-5">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2865.132184870507!2d-87.74951307429367!3d41.496849363236116!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880e112d1b18a8c3%3A0xeed461681889d531!2zQWRhbXMgU3QsIE1hdHRlc29uLCBJTCA2MDQ0Mywg0KHQqNCQ!5e1!3m2!1sru!2s!4v1661694163727!5m2!1sru!2s"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <h6 class="fw-bold">SEND US A MESSAGE</h6>
          <p class="text-danger mb-2">All fields below are required.</p>
          <form @submit.prevent="">
            <div class="input-wrapper gap-3 mb-2">
              <input
                v-model="firstName"
                class="form-control border"
                :class="{ 'border-danger': !firstName && isEmpty }"
                type="text"
                placeholder="First Name"
              />
              <input
                v-model="lastName"
                class="form-control border"
                :class="{ 'border-danger': !lastName && isEmpty }"
                type="text"
                placeholder="Last Name"
              />
              <input
                v-model="email"
                class="form-control border"
                :class="{ 'border-danger': !email && isEmpty }"
                type="email"
                placeholder="E-mail Address"
              />
            </div>
            <div class="input-wrapper gap-3 mb-2">
              <input
                v-model="city"
                class="form-control border"
                :class="{ 'border-danger': !city && isEmpty }"
                type="text"
                placeholder="City"
              />
              <input
                v-model="state"
                class="form-control border"
                :class="{ 'border-danger': !state && isEmpty }"
                type="text"
                placeholder="State"
              />
              <input
                v-model="country"
                class="form-control border"
                :class="{ 'border-danger': !country && isEmpty }"
                type="text"
                placeholder="Country"
              />
            </div>
            <div class="mb-3">
              <textarea
                v-model="comment"
                class="form-control border"
                :class="{ 'border-danger': !comment && isEmpty }"
                placeholder="Comment"
              ></textarea>
            </div>
            <div class="d-flex justify-content-center">
              <button
                @click="submitForm"
                type="submit"
                class="d-flex justify-content-center gap-2 align-items-center"
              >
                Submit <base-loader v-if="loading"></base-loader>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
  <base-footer></base-footer>
</template>

<script>
import customAxios from "../api";
import { defineAsyncComponent } from "vue";
const BaseLoader = defineAsyncComponent(() =>
  import("../components/UI/BaseLoader.vue")
);
import { checkEmail } from "../utils";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  components: {
    BaseLoader,
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      city: "",
      state: "",
      country: "",
      comment: "",
      isEmpty: false,
    };
  },
  computed: {
    loading() {
      return this.$store.state.isLoading;
    },
  },
  methods: {
    reset() {
      this.isEmpty = false;
      this.firstName = "";
      this.lastName = "";
      this.email = "";
      this.city = "";
      this.state = "";
      this.country = "";
      this.comment = "";
    },
    async submitForm() {
      if (
        !this.firstName ||
        !this.lastName ||
        !this.email ||
        !this.city ||
        !this.state ||
        !this.country ||
        !this.comment ||
        checkEmail(this.email)
      ) {
        this.isEmpty = true;
        return;
      }
      try {
        this.$store.dispatch("changeLoading", true);
        await customAxios.post("simple-form/", {
          first_name: this.firstName,
          last_name: this.lastName,
          email: this.email,
          recipient_email: this.recEmail,
          message: this.message,
          country: this.country,
          state: this.state,
          city: this.city,
        });
        this.$store.dispatch("changeLoading", false);
        this.reset();
        toast.success("Success!");
      } catch (e) {
        this.$store.dispatch("changeLoading", false);
        this.$store.dispatch("setError", e);
      }
    },
  },
};
</script>

<style scoped>
div iframe {
  width: 100%;
  height: 35vh;
}
.link-title {
  display: flex;
  justify-content: space-between;
}
.input-wrapper {
  display: flex;
}
.link-title input {
  flex: 1;
}
form button {
  width: 30%;
  background: #0057b7;
  color: #fff;
  padding: 4px 2.5rem;
  border-radius: 0.25rem;
  border: none;
}
form button:hover {
  background: #024690;
}
input.border-danger::placeholder,
textarea.border-danger::placeholder {
  color: #dc3545;
}
@media screen and (max-width: 992px) {
  .link-title {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
}
@media screen and (max-width: 768px) {
  .input-wrapper {
    flex-direction: column;
    gap: 6px !important;
  }
  button {
    padding: 4px 8px !important;
  }
}
@media screen and (max-width: 576px) {
  input::placeholder {
    font-size: 0.9rem;
  }
}
</style>
